<template>
    <div class="create_slot_place"
        v-if="position != -1" 
        v-bind:style="{
            'top': position + 'px',
            'height': tableCeilHeight() + 'px',
        }">
            New event
    </div>
</template>

<script>

export default {
    name: "CreatePlace",
    props: {
        position: Number,
    },
    updated() {
        console.log(`create-place: ${this.position}`)
    },
    methods: {
        tableCeilHeight(){
            return this.$store.getters.tableCeilSize.height
        }
    },
}
</script>

<style scoped>
.create_slot_place {
    position: absolute;
    width: 100% ;
    border: 3px dashed royalblue;
    color: royalblue;
    font-weight: 500;
    z-index: 2;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>